<template>
  <v-card class="ma-2" :height="height" :loading="loadingObj.loading">
    <v-card-title class="pa-4 pb-3">
      <div class="font-weight-medium subtitle-1">
        <span class=""> {{ tenantObj._id ? 'Update' : 'Create' }} </span>
        <span class=" grey--text text--darken-3"> tenant </span>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="success" class="mx-3" :disabled="!valid"  @click="SaveUpdateHandler"> {{ tenantObj._id ? 'Update' : 'Save' }} </v-btn>
      <v-btn color="error" @click="$router.push('/tenants')"> Cancel  </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="px-2 py-0">
      <v-card flat>
        <v-form ref="form" v-model="valid">
          <v-container fluid>
            <v-row dense class="py-1" v-if="!tenantObj._id">
              <v-col cols="12">
                <span class="font-weight-medium grey--text text--darken-3 d-flex"> Customer Information </span>
              </v-col>
              <v-col cols="12" xl="3" lg="3">
                <v-text-field outlined dense hide-details label="Name" v-model="tenantObj.name" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="12" xl="3" lg="3">
                <v-text-field outlined dense hide-details label="Email" v-model="tenantObj.email" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="12" xl="2" lg="3">
                <v-text-field outlined dense hide-details label="Phone" v-model="tenantObj.phone"></v-text-field>
              </v-col>
              <v-col cols="12" xl="2" lg="3">
                <v-text-field outlined dense hide-details label="Password" v-model="tenantObj.password" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="py-1">
              <v-col cols="12">
                <span class="font-weight-medium grey--text text--darken-3 d-flex"> Tenant Information </span>
              </v-col>
              <v-col cols="12" xl="2" lg="4" md="4" sm="4">
                <v-text-field
                  outlined dense hide-details label="Domain" v-model="tenantObj.domain" @keydown="domainValidation"
                  @input="inputLowerCase" :rules="setValidation('requiredValidation')" :disabled="!!tenantObj._id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xl="2" lg="4" md="4" sm="4">
                <v-text-field outlined dense hide-details label="Workspace Name" v-model="tenantObj.tenant" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="py-1">
              <v-col cols="12">
                <span class="font-weight-medium grey--text text--darken-3 body-2 d-flex"> Sharepoint Configuration </span>
              </v-col>
              <v-col cols="12" xl="3" lg="4" md="4" sm="4">
                <v-text-field
                  outlined dense hide-details label="Sharepoit site url" v-model="tenantObj.siteurl" placeholder="techilex.sharepoint.com/sites/{sitename}"
                  persistent-placeholder :loading="loadingObj.siteUrlLoading" @blur="getRootFolders" :rules="setValidation('requiredValidation')"
                  :disabled="loadingObj.siteUrlLoading || loadingObj.rootFoldersLoading"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xl="3" lg="4" md="4" sm="4">
                <v-select
                  outlined dense hide-details label="Sharepoit root folder" v-model="tenantObj.spRootFolderId"
                  :items="tenantObj.spRootFolders" item-text="name" item-value="id" :rules="setValidation('requiredValidation')"
                  :loading="loadingObj.rootFoldersLoading" :disabled="loadingObj.siteUrlLoading || loadingObj.rootFoldersLoading"
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row no-gutters class="py-3">
              <v-col cols="12">
                <span class="font-weight-medium grey--text text--darken-3 d-flex"> Tenant Features </span>
              </v-col>
              <v-col
                v-for="(feature, index) in listOfFeatures" :key="index"
                cols="12" xl="2" lg="2"
              >
                <v-checkbox
                  v-model="tenantObj.features" :label="feature" :value="feature" multiple dense hide-details
                ></v-checkbox>
              </v-col>
            </v-row> -->
          </v-container>
        </v-form>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
import mixins from '@/mixins/constants'
export default {
  mixins: [mixins],
  data () {
    return {
      height: (window.innerHeight - 78),
      valid: false,
      tenantObj: {
        tenant: 'My workspace',
        isdomainadmin: true,
        siteurl: 'techilex.sharepoint.com/sites/'
      },
      loadingObj: {
        loading: false,
        siteUrlLoading: false,
        rootFoldersLoading: false,
      }
    }
  },
  computed: {
    listOfFeatures () {
      return [...Object.keys(this.features)]
    }
  },
  mounted () {
    if (this.$route.params.id) this.getSingleRecordHandler(this.$route.params.id)

    window.onmessage = ({ data } = {}) => {
      if (data && data.code) {
        this.loginHandler(data.code)
      }
    }

    window.onresize = () => {
      this.height = window.innerHeight - 78
    }
  },
  methods: {
    getRootFolders ({ loadingField = 'siteUrlLoading' } = {}) {
      const payload = { siteurl: this.tenantObj.siteurl }
      this.loadingObj[loadingField] = true
      this.$_execute('post', '/sharepoint/getSharepointRootFolders', payload)
        .then(({ data }) => {
          this.tenantObj = {
            ...this.tenantObj,
            ...data
          }
        })
        .catch(({ response: errRes } = {}) => {
          this.tenantObj = {
            ...this.tenantObj,
            spRootFolders: [],
            spRootFolderId: null,
          }
          if (errRes?.status === 461) this.getSharepointUrl()
        })
        .finally(() => {
          this.loadingObj[loadingField] = false
        })
    },
    getSharepointUrl () {
      this.$_execute('get', 'microsoft/get-authurl')
        .then(({ data }) => {
          window.open(data, '_blank', 'left=100, top=140, toolbar=yes, status=yes, menubar=yes, scrollbars=no, resizable=yes, copyhistory=no, height=400, width=500')
        })
    },
    loginHandler (code) {
      this.$_execute('post', 'microsoft/validate-user', { code })
        .then(() => {
          this.$root.$emit('snackbar', { message: 'Sharepoint config. data updated', color: 'success' })
          this.getRootFolders()
        })
    },
    domainValidation (ev) {
      if (ev.code === 'Space') {
        ev.preventDefault()
        return
      }
    },
    inputLowerCase () {
      this.tenantObj.domain = this.tenantObj.domain.toLowerCase()
    },
    getSingleRecordHandler (id) {
      this.loadingObj.loading = true
      this.$_execute('get', `tenants/${id}`)
        .then(({ data }) => {
          if (data.length) {
            this.tenantObj = data[0]
            this.getRootFolders({ loadingField: 'rootFoldersLoading' })
          } else {
            this.$router.push('/tenants')
          }
        })
        .finally(() => {
          this.loadingObj.loading = false
        })
    },
    SaveUpdateHandler() {
      this.loadingObj.loading = true
      let url = (this.tenantObj._id) ? `/tenants/${this.tenantObj._id}` : 'signup'
      this.$_execute((this.tenantObj._id ? 'put' : 'post'), url, this.tenantObj)
        .then(({ data } = {}) => {
          this.$root.$emit('snackbar', { message: (this.tenantObj._id) ? 'Tenant updated Successfully' : 'Tenant created Successfully', color: 'success' })
          this.$router.push(`/tenants/actions/${data._id}`)
        })
        .finally(() => {
          this.loadingObj.loading = false
        })
    }
  }
}
</script>
