export default {
  data () {
    return {
      USER_ROLE: {
        ADMIN: 1,
        STAFF: 2,
        STUDENT: 3
      },
      modules: {
        CALENDAR: 'calendar',
        ATTENDANCE: 'attendance',
        LEAVE: 'leave',
        USERS: 'users',
        REPORTS: 'reports',
        ACADEMIC: 'academic',
      },
      features: {
        ACCOUNTING: 'accounting',
        CHAT: 'chat',
        LIBRARY: 'library',
        FORMS: 'forms',
        EXAMS: 'exams',
        TRANPORTAION: 'transportation',
        HOSTEL: 'hostel',
        GATEPASS: 'gatepass',
        ENQUIRY: 'enquiry',
      }
    }
  }
}
